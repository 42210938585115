<template>
  <div>
      <div class="device">
        <div class="displayScreen">
          <div style="flex:1.5" v-if="ON">Account number : <div class="font" style="font-size: 18px;">{{ AccountNumber }}</div></div>
          <div style="flex:1.5" v-if="ON">Transatction amount : <div class="font" style="font-size: 18px;">{{ TransatctionAmount!=''? ('$'+TransatctionAmount):TransatctionAmount}}</div></div>
          <div v-if="ON">prompt : <span class="font">{{ Prompt!=''? (Prompt):Prompt}}</span></div>
          <div class="decorate"></div>
        </div>
        <div class="function">
          <button class="button1" @click="payFun()">PAY</button>
          <button  @click="payFun()">eft</button>
          <button style="font-size: 18px;" @click="delFun()">⬅</button>
          <button><i class="el-icon-switch-button" style="font-weight: bolder;" @click="onFun()"></i></button>
        </div>
        <div class="numericKeypad">
          <button @click="inputFun('1')">1</button>
          <button @click="inputFun('2')">2</button>
          <button style="margin: 0;" @click="inputFun('3')">3</button>
          <button @click="inputFun('4')">4</button>
          <button @click="inputFun('5')">5</button>
          <button style="margin: 0;" @click="inputFun('6')">6</button>
          <button @click="inputFun('7')">7</button>
          <button @click="inputFun('8')">8</button>
          <button style="margin: 0;" @click="inputFun('9')">9</button>
          <button @click="inputFun('.')">.</button>
          <button @click="inputFun('0')">0</button>
          <button style="margin: 0;font-size: 14px;" @click="okFun()">OK</button>
        </div>
      </div>
      <strong>
        Account: {{ Account }}
      </strong>
  </div>
</template>

<script>
export default {
    name: 'DeviceComponent',
    props:['Account'],
    data(){
      return{
        ON:false,
        AccountNumber:'',
        TransatctionAmount:'',
        Prompt:'',
        AccountLock:true,
        number:0,

        // comrlete
        // fail
      }
    },
    methods:{
      payFun(){
        this.AccountNumber = '';
        this.TransatctionAmount = '';
        this.Prompt = '';
        this.AccountLock = true;
        this.number = 0;
      },
      inputFun(num){
        if(this.AccountLock == true){
          if(this.AccountNumber.length < 19 && num != '.')
          this.AccountNumber = this.AccountNumber + num;
        }else{
          if(this.TransatctionAmount.indexOf(".") != -1){
            if(num != '.'){
              if(this.number == 0)
              this.number = this.TransatctionAmount.length + 2;
              if(this.TransatctionAmount.length < this.number){
                this.TransatctionAmount = this.TransatctionAmount + num;
              }
            }
          }else{
            if(num != '.'){
              if(this.TransatctionAmount.length < 5){
                this.TransatctionAmount = this.TransatctionAmount + num;
              }
            }else{
              if(this.TransatctionAmount.length > 0){
                this.TransatctionAmount = this.TransatctionAmount + num;
              }
            }
            
          }
          
        }
      },
      delFun(){
        if(this.Prompt != ''){
          this.AccountNumber = '';
          this.TransatctionAmount = '';
          this.Prompt = '';
          this.AccountLock = true;
          this.number = 0;
        }else if(this.AccountLock == true){
          this.AccountNumber = this.AccountNumber.substring(0, this.AccountNumber.length - 1);
        }else{
          this.TransatctionAmount = this.TransatctionAmount.substring(0, this.TransatctionAmount.length - 1);
          if(!(this.TransatctionAmount.indexOf(".") != -1)){
            this.number = 0;
          }
        }
      },
      onFun(){
        if(this.ON == true){
          this.AccountNumber = '';
          this.TransatctionAmount = '';
          this.Prompt = '';
          this.AccountLock = true;
          this.ON = false;
          this.number = 0;
        }else{
          this.ON = true;
        }
      },
     
      okFun(){
        if(this.AccountLock == true && this.AccountNumber != ''){
          this.AccountLock = false;
        }else if(this.AccountLock == false && this.AccountNumber != '' && this.TransatctionAmount != ''){
          this.number = 0;
          if(parseFloat(this.TransatctionAmount) > 10000){
            this.Prompt = 'fail';
          }else{
            this.Prompt = 'comrlete';
          }
          
        }
      }
    }
}

</script>

<style  lang="less" scoped>

.device{
  width: 270px;
  height: 400px;
  border-radius: 20px 20px 20px 20px;
  background-color: #dfdfdf;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  border: 5px solid #bb1515;
  box-shadow: 10px 10px 15px gray;
  margin-bottom: 35px;
  .displayScreen{
    text-align: left;
    font-size: 16px;
    font-weight: bolder;
    color: #000;
    width: 100%;
    height: 130px;
    background: linear-gradient(rgb(238, 250, 255),rgb(201, 237, 243),rgb(192, 247, 255));
    border:3px solid black;
    box-sizing: border-box;
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
    div{
      flex:1
    }
    .font{
      font-family: "electronicFont";
      font-weight: bolder;
    }
  }
  .displayScreen::before{
    width: 0;
    height: 0;
    position: absolute;
    content: '';
    top: -2px;
    left: -2px;
    border: 10px solid #000;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
  .displayScreen::after{
    width: 0;
    height: 0;
    position: absolute;
    content: '';
    top: -4px;
    left: -4px;
    border: 10px solid #dfdfdf;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
  .decorate{
    position: absolute;
    bottom: -20px;
    right: -3px;
    width: 100px;
    height: 20px;
    background-color: black;
  }
  .decorate::before{
    width: 0;
    height: 0;
    position: absolute;
    content: '';
    top: 3px;
    left: 0px;
    border: 10px solid #dfdfdf;
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .function{
    width: 100%;
    height: 65px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 5px;
    padding-bottom: 0;
    .button1{
      width:50px;
      height:50px;
      border-radius: 100px;
      border: none;
      background-color: #bb1515;
      box-shadow: 2px 2px 10px #545454;
      font-size: 20px;
    }
    button{
      width:35px;
      height:35px;
      border-radius: 100px;
      font-weight: bold;
      border: none;
      background-color: #efefef;
      box-shadow: 2px 2px 10px #afafaf;
    }
    button:active{
      transform:translate(1px,1px);
    }
  }
  .numericKeypad{
    width: 100%;
    height:140px;
    margin-top: 15px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    button{
      width:60px;
      height:25px;
      font-size: 20px;
      border-radius: 100px;
      font-weight: bold;
      border: none;
      background-color: #efefef;
      box-shadow: 2px 2px 10px #afafaf;
      margin-right: 18px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    button:active{
      transform:translate(1px,1px);
    }
  }

}
.device::before{
    width: 0;
    height: 0;
    position: absolute;
    content: '';
    top: -2px;
    left: -2px;
    border: 15px solid rgb(187, 21, 21);
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.device::after{
  width: 0;
  height: 0;
  position: absolute;
  content: '';
  top: -5.5px;
  left: -5.5px;
  border: 15px solid antiquewhite;
  border-bottom-color: transparent;
  border-right-color: transparent;
}


 
</style>