<template>
  <div>
    <h1>Ucreaty</h1>
    <div class="box">
      <div class="deviceBox">
        <DeviceComponent :Account="123123"/>
      </div>
      <i class="el-icon-upload" style="font-size: 100px;margin-bottom: 200px;"></i>
      <div class="deviceBox">
        <DeviceComponent :Account="456456"/>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceComponent from '../components/Device.vue'
export default {
  name: 'HomePage',
  components: {
    DeviceComponent
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less" scoped>
.box{
  height: 600px;
  box-sizing: border-box;
  // background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  .deviceBox{
    width: 450px;
    height: 600px;
    // background-color: rgb(165, 165, 165);
    margin: 0 60px;
    padding: 100px;
    box-sizing:  border-box;
    
  }
}
</style>
